import React, { useState } from "react";
import validator from 'validator';
const defoltData = {
    'url': window.location.href,
    'type': '',
    'app_name': process.env.REACT_APP_NAME,
    'full_name': '',
    'phone_number': '',
    'email': '',
    'subject': '',
    'country': '',
    'message': ''
}

const ContactForm = ({ type = 'Contact' }) => {
    defoltData.type = type;
    const [data, setData] = useState(defoltData);
    const [status, setStatus] = useState(false);
    const [sucess, setSucess] = useState(false);
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value
        }))
        validateField(name, value);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        // if(status){
        const newErrors = validateForm(data);
        if (Object.keys(newErrors).length === 0) {
            fetch(process.env.REACT_APP_API_URL + '/contact', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(() => {
                setData(defoltData);
                setSucess(true);
            })
        } else {
            setErrors(newErrors);
        }

        // }
    }
    const validateField = (name, value) => {
        const fieldErrors = {};
        switch (name) {
            case 'email':
                if (!validator.isEmail(value)) {
                    fieldErrors.email = 'Invalid email address';
                } else {
                    fieldErrors.email = '';
                }
                break;
            case 'full_name':
                if (validator.isEmpty(value.trim())) {
                    fieldErrors.full_name = 'Name is required';
                } else {
                    fieldErrors.full_name = '';
                }
                break;
            case 'subject':
                if (validator.isEmpty(value.trim())) {
                    fieldErrors.subject = 'Subject is required';
                } else {
                    fieldErrors.subject = '';
                }
                break;
            case 'country':
                if (validator.isEmpty(value.trim())) {
                    fieldErrors.country = 'Country name is required';
                } else {
                    fieldErrors.country = '';
                }
                break;
            case 'message':
                if (validator.isEmpty(value.trim())) {
                    fieldErrors.message = 'Message is required';
                } else {
                    fieldErrors.message = '';
                }
                break;
            case 'phone_number':
                if (value !== '') {
                    if (!validator.isMobilePhone(value, 'any', { strictMode: false }) || value.length < 7) {
                        fieldErrors.phone_number = 'Invalid phone number';
                    } else {
                        fieldErrors.phone_number = '';
                    }
                }
                else {
                    fieldErrors.phone_number = 'Phone number is required';
                }
                break;
            default:
                break;
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            ...fieldErrors
        }));
    };
    const validateForm = (data) => {
        const errors = {};
        if (!validator.isEmail(data.email)) {
            errors.email = 'Invalid email address';
        }
        if (validator.isEmpty(data.full_name.trim())) {
            errors.full_name = 'Name is required';
        }
        if (validator.isEmpty(data.subject.trim())) {
            errors.subject = 'Subject is required';
        }
        if (validator.isEmpty(data.country.trim())) {
            errors.country = ' Country name is required';
        }
        if (validator.isEmpty(data.message.trim())) {
            errors.message = 'Message is required';
        }
        if (!validator.isMobilePhone(data.phone_number, 'any', { strictMode: false }) || data.phone_number.length < 7) {
            errors.phone_number = 'Phone number is required';
        }
        return errors;
    };
    return (
        <form onSubmit={handleSubmit} className='row ttm-quote-form clearfix' >
            <div className='col-sm-6 col-md-6'>
                <div className='form-group'>
                    <input type='text' className={`form-control bg-white border ${errors.full_name ? 'is-invalid' : ''}`} name='full_name' placeholder='Full Name&#x2a;' value={data.full_name} onChange={handleChange} />
                    {errors.full_name && <div className="invalid-feedback">{errors.full_name}</div>}
                </div>
            </div>
            <div className='col-sm-6 col-md-6'>
                <div className='form-group'>
                    <input type='text' className={`form-control bg-white border ${errors.phone_number ? 'is-invalid' : ''}`} name='phone_number' placeholder='Phone Number&#x2a;' value={data.phone_number} onChange={handleChange} />
                    {errors.phone_number && <div className="invalid-feedback">{errors.phone_number}</div>}
                </div>
            </div>
            <div className='col-sm-12 col-md-12'>
                <div className='form-group'>
                    <input type='email' className={`form-control bg-white border  ${errors.email ? 'is-invalid' : ''}`} name='email' placeholder='Email Address&#x2a;' value={data.email} onChange={handleChange} />
                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                </div>
            </div>
            <div className='col-sm-6 col-md-6'>
                <div className='form-group'>
                    <input type='text' className={`form-control bg-white border ${errors.subject ? 'is-invalid' : ''}`} name='subject' placeholder='Subject&#x2a;' value={data.subject} onChange={handleChange} />
                    {errors.subject && <div className="invalid-feedback">{errors.subject}</div>}
                </div>
            </div>
            <div className='col-sm-6 col-md-6'>
                <div className='form-group'>
                    <input type='text' className={`form-control bg-white border ${errors.country ? 'is-invalid' : ''}`} name='country' placeholder='Country&#x2a;' value={data.country} onChange={handleChange} />
                    {errors.country && <div className="invalid-feedback">{errors.country}</div>}
                </div>
            </div>
            <div className='col-sm-12 col-md-12'>
                <div className='form-group'>
                    <textarea name='message' className={`form-control bg-white border ${errors.message ? 'is-invalid' : ''}`} rows={5} placeholder='Write a message...' onChange={handleChange} value={data.message}> </textarea>
                    {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                </div>
            </div>
            {/* <div className='col-md-12'>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={() => {
                        setStatus(true);
                    }}
                />
            </div> */}
            {sucess && (
                <div className='col-sm-12 col-md-12'>
                    <div className="alert alert-success mt-3" role="alert">
                        Thank you for filling out your information!
                    </div>
                </div>
            )}
            <div className='col-md-12'>
                <div className='text-left'>
                    <button type='submit' id='submit' className='mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor'>
                        Submit Quote
                    </button>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;