import { Suspense, lazy } from 'react';
import { Loader } from './index';

const Lazy = (props) => {
    const Component = lazy(() => import('../../views/' + props.view));
    return (
        <Suspense fallback={<Loader />}>
            <Component {...props} />
        </Suspense>
    );
};

export default Lazy;
