import React from 'react';
import { Outlet } from 'react-router-dom';

import ScrollToTop from './Gotop';
import Header from './Header';
import Footer from './Footer';

export default function AppLayout() {
	return (
		<div className='page'>
            <ScrollToTop />
            <Header />
            <Outlet />
			<Footer />
        </div>
	);
}