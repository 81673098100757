import React from 'react';
import { Navigate, useRoutes, Outlet } from 'react-router-dom';
import { Lazy } from './components/Loader';

import AppLayout from './components/layout/App';
import CaseStudies from './views/CaseStudies/CaseStudies';

export default function Router() {
	return useRoutes([
		{
			path: '/',
			element: <AppLayout />,
			children: [
				{ path: '/', element: <Lazy view={'Home'} /> },
				{ path: 'contact', element: <Lazy view={'pages/Contact'} /> },
				{ path: 'aboutus', element: <Lazy view={'pages/Aboutus'} /> },
				{ path: 'careers', element: <Lazy view={'pages/Careers'} /> },
				{ path: 'cancellation-and-refund-policy', element: <Lazy view={'pages/CancellationAndRefundPolicy'} /> },
				{ path: 'terms-conditions', element: <Lazy view={'pages/TermsConditions'} /> },
				{ path: 'privacy-policy', element: <Lazy view={'pages/PrivacyPolicy'} /> },
				{ path: 'our-working-process', element: <Lazy view={'pages/OurWorkingProcess'} /> },
				{ path: 'faq', element: <Lazy view={'pages/Faq'} /> },
				{ path: 'cookie', element: <Lazy view={'pages/Cookie'} /> },
				{ path: 'sales-partner-program', element: <Lazy view={'pages/SalesPartnerProgram'} /> },
				{ path: 'testimonial', element: <Lazy view={'pages/Testimonial/list'} /> },

				{
					path: 'competency-matrix',
					element: <Outlet />,
					children: [
						{ path: 'block-chain', element: <Lazy view={'CompetencyMatrix/BlockChain'} /> },
						{ path: 'artificial-intelligence', element: <Lazy view={'CompetencyMatrix/ArtificialIntelligence'} /> },
						{ path: 'data-science', element: <Lazy view={'CompetencyMatrix/DataScience'} /> },
						{ path: 'enterprise-web-application', element: <Lazy view={'CompetencyMatrix/EnterpriseWebApplication'} /> },
						{ path: 'enterprise-mobility', element: <Lazy view={'CompetencyMatrix/EnterpriseMobility'} /> },
						{ path: 'quality-assurance', element: <Lazy view={'CompetencyMatrix/QualityAssurance'} /> },
						{ path: 'cloud-computing', element: <Lazy view={'CompetencyMatrix/CloudComputing'} /> },
						{ path: 'internet-of-things', element: <Lazy view={'CompetencyMatrix/InternetOfThings'} /> },
						{ path: 'devops', element: <Lazy view={'CompetencyMatrix/Devops'} /> },
					]
				}, {
					path: 'professional-services',
					element: <Outlet />,
					children: [
						{ path: 'maintenance-support', element: <Lazy view={'ProfessionalServices/MaintenanceSupport'} /> },
						{ path: 'it-consulting', element: <Lazy view={'ProfessionalServices/ITConsulting'} /> },
						{ path: 'custom-app-development', element: <Lazy view={'ProfessionalServices/CustomAppDevelopment'} /> },
						{ path: 'mobile-app-development', element: <Lazy view={'ProfessionalServices/MobileAppDevelopment'} /> },
						{ path: 'emerging-tech-development', element: <Lazy view={'ProfessionalServices/EmergingTechDevelopment'} /> },
						{ path: 'application-integration', element: <Lazy view={'ProfessionalServices/ApplicationIntegration'} /> },
					]
				}, {
					path: 'industry-focus',
					element: <Outlet />,
					children: [
						{ path: 'healthcare-pharmaceutical', element: <Lazy view={'IndustryFocus/HealthcarePharmaceutical'} /> },
						{ path: 'hi-tech-manufacturing', element: <Lazy view={'IndustryFocus/HiTechManufacturing'} /> },
						{ path: 'e-commerce-retail', element: <Lazy view={'IndustryFocus/ECommerceRetail'} /> },
						{ path: 'bank-financial-services', element: <Lazy view={'IndustryFocus/BankFinancialServices'} /> },
						{ path: 'mining-jewellery', element: <Lazy view={'IndustryFocus/MiningJewellery'} /> },
						{ path: 'it-telecom', element: <Lazy view={'IndustryFocus/ITTelecom'} /> },
						{ path: 'automotive', element: <Lazy view={'IndustryFocus/Automotive'} /> },
						{ path: 'education-academics', element: <Lazy view={'IndustryFocus/EducationAcademics'} /> },
						{ path: 'others', element: <Lazy view={'IndustryFocus/Others'} /> },
					]
				}, {
					path: 'case-studies',
					element: <CaseStudies />,
					children: [
						{ path: '', element: <Lazy view={'CaseStudies/List'} /> },
						{ path: 'online-travel-platform', element: <Lazy view={'CaseStudies/OnlineTravelPlatform'} /> },
						{ path: 'online-medical-consultation', element: <Lazy view={'CaseStudies/OnlineMedicalConsultation'} /> },
						{ path: 'time-funding', element: <Lazy view={'CaseStudies/TimeFunding'} /> },
						{ path: 'pharmacy-order-management', element: <Lazy view={'CaseStudies/PharmacyOrderManagement'} /> },
						{ path: 'automated-workforce-management', element: <Lazy view={'CaseStudies/AutomatedWorkforceManagement'} /> },
						{ path: 'school-scheduling-software', element: <Lazy view={'CaseStudies/SchoolSchedulingSoftware'} /> },
						{ path: 'crowdfunding-platform', element: <Lazy view={'CaseStudies/CrowdfundingPlatform'} /> },
						{ path: 'video-conferencing-platform', element: <Lazy view={'CaseStudies/VideoConferencingPlatform'} /> },
						{ path: 'student-performance-tracking', element: <Lazy view={'CaseStudies/StudentPerformanceTracking'} /> },

						{ path: 'automobiles-it-staff-management', element: <Lazy view={'CaseStudies/AutomobilesItStaffManagement'} /> },
						{ path: 'online-logistics-system', element: <Lazy view={'CaseStudies/OnlineLogisticsSystem'} /> },
						{ path: 'customer-relationship-management', element: <Lazy view={'CaseStudies/CustomerRelationshipManagement'} /> },
						{ path: 'delivery-management-system', element: <Lazy view={'CaseStudies/DeliveryManagementSystem'} /> },
						{ path: 'multilevel-marketing-mlm', element: <Lazy view={'CaseStudies/MultilevelMarketingMlm'} /> },
						{ path: 'wedding-event-planner', element: <Lazy view={'CaseStudies/WeddingEventPlanner'} /> },
						{ path: 'trade-union-website-redeveloped', element: <Lazy view={'CaseStudies/TradeUnionWebsiteRedeveloped'} /> },
						{ path: 'project-management-application', element: <Lazy view={'CaseStudies/ProjectManagementApplication'} /> },
						{ path: 'project-management-and-time-schedule', element: <Lazy view={'CaseStudies/ProjectManagementAndTimeSchedule'} /> },

						{ path: 'online-parcel-delivery-system', element: <Lazy view={'CaseStudies/OnlineParcelDeliverySystem'} /> },
						{ path: 'online-food-delivery-and-events-booking', element: <Lazy view={'CaseStudies/OnlineFoodDeliveryAndEventsBooking'} /> },
						{ path: 'e-learning-in-music', element: <Lazy view={'CaseStudies/ELearningInMusic'} /> },
						{ path: 'e-learning-training', element: <Lazy view={'CaseStudies/ELearningTraining'} /> },
						{ path: 'ecommerce', element: <Lazy view={'CaseStudies/Ecommerce'} /> },
						{ path: 'ebooks-vendor', element: <Lazy view={'CaseStudies/EbooksVendor'} /> },
						{ path: 'task-management-system', element: <Lazy view={'CaseStudies/TaskManagementSystem'} /> }
					]
				}, {
					path: 'blog',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Blog/List'} /> },
						{ path: 'clutch-names-spidron-tech-as-a-top-developer-in-india-for-2022', element: <Lazy view={'Blog/TopDeveloperInIndiaFor2022'} /> },
						{ path: 'covid', element: <Lazy view={'Blog/Covid'} /> },
						{ path: 'ui-ux-design', element: <Lazy view={'Blog/UIUXDesign'} /> },
						{ path: 'front-end-development', element: <Lazy view={'Blog/FrontEndDevelopment'} /> },
						{ path: 'full-stack-development', element: <Lazy view={'Blog/FullStackDevelopment'} /> },
						{ path: 'back-end-development', element: <Lazy view={'Blog/BackEndDevelopment'} /> },
						{ path: 'certified-cloud-expert', element: <Lazy view={'Blog/CertifiedCloudExpert'} /> },
						{ path: 'certified-kubernetes-expert', element: <Lazy view={'Blog/CertifiedKubernetesExpert'} /> },
						{ path: 'certified-devops-expert', element: <Lazy view={'Blog/CertifiedDevOpsExpert'} /> },
						{ path: 'ios-development', element: <Lazy view={'Blog/IOSDevelopment'} /> },
						{ path: 'android-development', element: <Lazy view={'Blog/AndroidDevelopment'} /> },
						{ path: 'hybrid-development', element: <Lazy view={'Blog/HybridDevelopment'} /> },
						{ path: 'web-testing', element: <Lazy view={'Blog/WebTesting'} /> },
						{ path: 'api-testing', element: <Lazy view={'Blog/APITesting'} /> },
						{ path: 'performance-testing', element: <Lazy view={'Blog/PerformanceTesting'} /> },
						{ path: 'test-automation', element: <Lazy view={'Blog/TestAutomation'} /> },
						{ path: 'mobile-testing', element: <Lazy view={'Blog/MobileTesting'} /> },
					]
				},
				{ path: '/ui-ux-design', element: <Navigate to='/blog/ui-ux-design' replace /> },
				{ path: '/front-end-development', element: <Navigate to='/blog/front-end-development' replace /> },
				{ path: '/full-stack-development', element: <Navigate to='/blog/full-stack-development' replace /> },
				{ path: '/back-end-development', element: <Navigate to='/blog/back-end-development' replace /> },
				{ path: '/certified-cloud-expert', element: <Navigate to='/blog/certified-cloud-expert' replace /> },
				{ path: '/certified-kubernetes-expert', element: <Navigate to='/blog/certified-kubernetes-expert' replace /> },
				{ path: '/certified-devops-expert', element: <Navigate to='/blog/certified-devops-expert' replace /> },
				{ path: '/ios-development', element: <Navigate to='/blog/ios-development' replace /> },
				{ path: '/android-development', element: <Navigate to='/blog/android-development' replace /> },
				{ path: '/hybrid-development', element: <Navigate to='/blog/hybrid-development' replace /> },
				{ path: '/web-testing', element: <Navigate to='/blog/web-testing' replace /> },
				{ path: '/api-testing', element: <Navigate to='/blog/api-testing' replace /> },
				{ path: '/performance-testing', element: <Navigate to='/blog/performance-testing' replace /> },
				{ path: '/test-automation', element: <Navigate to='/blog/test-automation' replace /> },
				{ path: '/mobile-testing', element: <Navigate to='/blog/mobile-testing' replace /> },
				{

					path: 'industry-trends',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'IndustryTrends/List'} /> },
						{ path: 'customer-self-service', element: <Lazy view={'IndustryTrends/CustomerSelfService'} /> },
						{ path: 'facial-recognition', element: <Lazy view={'IndustryTrends/FacialRecognition'} /> },
					]
				},
				{ path: '/services', element: <Navigate to='/' replace /> },
				{ path: '/portfolio', element: <Navigate to='/case-studies' replace /> },
				{ path: '/certification', element: <Navigate to='/aboutus' replace /> },
				{ path: '/project-estimate', element: <Navigate to='/contact' replace /> },
				{ path: '/contact-us', element: <Navigate to='/contact' replace /> },
				{ path: '/about-us', element: <Navigate to='/aboutus' replace /> },

				{ path: 'error', element: <Lazy view={'pages/Error'} /> },
				{ path: '*', element: <Navigate to='/error' replace /> }
			]
		}
	]);
}