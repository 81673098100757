import React from 'react'
import { Link } from 'react-router-dom';

import footerLogoImg from '../../assets/images/footer-logo.webp';
import BlogsData from '../../views/Blog/data';


const Footer = () => {
    const threeBlogsForFooter = () => {
        return randomArrayShuffle([...BlogsData]).slice(0, 3);
    };

    function randomArrayShuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;
        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }

    return (
        <footer className='footer widget-footer clearfix'>
            <div className='first-footer ttm-bgcolor-skincolor ttm-bg ttm-bgimage-yes bg-img1'>
                <div className='ttm-row-wrapper-bg-layer ttm-bg-layer' />
                <div className='container-xl'>
                    <div className='row align-items-md-center'>
                        <div className='col-lg-4 col-md-4 col-sm-12 order-md-2'>
                            <div className='footer-logo text-sm-center'>
                                <img data-src={footerLogoImg} className='img-fluid' alt='footer-logo' src={footerLogoImg} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-6 order-md-1 res-767-mt-20'>
                            <div className='text-left'>

                                <div className='featured-icon-box left-icon icon-align-top'>
                                    <div className='featured-icon'>
                                        <div className='ttm-icon ttm-textcolor-white ttm-icon_element-size-md'>
                                            <i className='ti ti-location-pin' />
                                        </div>
                                    </div>
                                    <div className='featured-content'>
                                        <div className='featured-desc'>
                                            <p className='text-justify'>9 Hardy's Avenue Leicester LE4 7SB, UK</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-6 order-md-4 res-767-mt-20'>
                            <div className='text-sm-right'>
                                <a href='mailto:contact@spidrontech.com' className='ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white'>
                                    <i className='far fa-envelope' /> contact@spidrontech.com
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='second-footer ttm-textcolor-white bg-img2'>
                <div className='container-xl'>
                    <div className='row'>
                        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area'>
                            <div className='widget widget_text  clearfix'>
                                <h3 className='widget-title'>About SpidronTech</h3>
                                <p className='textwidget widget-text text-justify'>
                                    Spidrontech is revolutionizing the way businesses operate by practicing the adoption of steadily evolving technology. We are accelerating business transformation through a smart, secure and connected experience.
                                </p>
                                <div className='quicklink-box'>

                                    <div className='featured-icon-box left-icon'>
                                        <div className='featured-icon'>
                                            <div className='ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle'>
                                                <span className='flaticon flaticon-clock' />
                                            </div>
                                        </div>
                                        <div className='featured-content'>
                                            <div className='featured-desc'>
                                                <p>Talk To Our Support</p>
                                            </div>
                                            <div className='featured-title'>
                                                <h5>+44 7440 256305</h5>
                                                <h5>+91 80871 23222</h5>
                                                <h5>+33 64465 6946</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area'>
                            <div className='widget link-widget clearfix'>
                                <h3 className='widget-title'>Quick Links</h3>
                                <ul id='menu-footer-services'>
                                    <li><Link to='/terms-conditions'>Terms Conditions</Link></li>
                                    <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                                    <li><Link to='/our-working-process'>Our Working Process</Link></li>
                                    <li><Link to='/sales-partner-program'>Sales Partner Program</Link></li>
                                    <li><Link to='/cancellation-and-refund-policy'>Cancellation And Refund Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area res-991-mb-40 res-767-mb-0'>
                            <div className='widget widget_text clearfix'>
                                <h3 className='widget-title'>Blogs</h3>
                                <ul className='widget-post ttm-recent-post-list'>
                                    {threeBlogsForFooter().map((item) => (
                                        <li key={item.id}>
                                            <Link to={item.link}><img className='img-fluid' src={item.imag} alt={item.name} /></Link>
                                            <Link to={item.link}>{item.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area res-767-mb-40'>
                            <div className='widget link-widget clearfix'>
                                <h3 className='widget-title'>Resources</h3>
                                <ul id='menu-footer-services'>
                                    <li><Link to='/faq'>FAQs</Link></li>
                                    <li><Link to='/blog'>Blog</Link></li>
                                    <li><Link to='/testimonial'>Testimonial</Link></li>
                                    <li><Link to='/cookie'>Cookie</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white'>
                <div className='container-xl'>
                    <div className='row copyright align-items-center res-767-text-center'>
                        <div className='col-md-6'>
                            <div>
                                <span>Copyright © 2020&nbsp;<Link to='/'>SpidronTech</Link></span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='text-md-right res-767-mt-10'>
                                <div className='d-lg-inline-flex'>
                                    <ul id='menu-footer-menu' className='footer-nav-menu'>
                                        <li><Link to='/aboutus'>About Us</Link></li>
                                        <li><Link to='/industry-trends'>Industry Trends</Link></li>
                                        <li><Link to='/careers'>Careers</Link></li>
                                        <li><Link to='/contact'>Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;