import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import blockChainImg from '../../assets/images/competency-matrix/block-chain/menu.webp';
import artificialIntelligenceImg from '../../assets/images/competency-matrix/artificial-intelligence/menu.webp';
import dataScienceImg from '../../assets/images/competency-matrix/data-science/menu.webp';
import enterpriseWebApplicationImg from '../../assets/images/competency-matrix/enterprise-web-application/menu.webp';
import enterpriseMobilityImg from '../../assets/images/competency-matrix/enterprise-mobility/menu.webp';
import qualityAssuranceImg from '../../assets/images/competency-matrix/quality-assurance/menu.webp';
import cloudComputingImg from '../../assets/images/competency-matrix/cloud-computing/menu.webp';
import internetOfThingsImg from '../../assets/images/competency-matrix/internet-of-things/menu.webp';
import devopsImg from '../../assets/images/competency-matrix/devops/menu.webp';

import maintenanceSupportImg from '../../assets/images/professional-services/maintenance-support/menu.webp';
import itConsultingImg from '../../assets/images/professional-services/it-consulting/menu.webp';
import customAppDevelopmentImg from '../../assets/images/professional-services/custom-app-development/menu.webp';
import mobileAppDevelopmentImg from '../../assets/images/professional-services/mobile-app-development/menu.webp';
import emergingTechDevelopmentImg from '../../assets/images/professional-services/emerging-tech-development/menu.webp';
import applicationIntegrationImg from '../../assets/images/professional-services/application-integration/menu.webp';

import healthcarePharmaceuticalImg from '../../assets/images/industry-focus/healthcare-pharmaceutical/menu.webp';
import hiTechManufacturingImg from '../../assets/images/industry-focus/hi-tech-manufacturing/menu.webp';
import eCommerceRetailImg from '../../assets/images/industry-focus/e-commerce-retail/menu.webp';
import bankFinancialServicesImg from '../../assets/images/industry-focus/bank-financial-services/menu.webp';
import miningJewelleryImg from '../../assets/images/industry-focus/mining-jewellery/menu.webp';
import itTelecomImg from '../../assets/images/industry-focus/it-telecom/menu.webp';
import automotiveImg from '../../assets/images/industry-focus/automotive/menu.webp';
import educationAcademicsImg from '../../assets/images/industry-focus/education-academics/menu.webp';
import othersImg from '../../assets/images/industry-focus/others/menu.webp';
import { useLocation } from 'react-use';

export const Menu = () => {
    const navigation = useNavigate();
    const location = useLocation();
    const redirect = (path) => {
        if (!(location.pathname === path)) {

            navigation(path)
        }
        
    }
    return (
        <nav id='menu' role='navigation' className='menu'>
            <ul>
                <li className='mega-hasdropdown'>
                    {/* <Link to='#'>Competency Matrix</Link> */}
                    <a>Competency Matrix</a>
                    <div className='mega-dropdown'>
                        <div className='row m-3'>
                            <div className='col-lg-4'>
                                <ul>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={blockChainImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/competency-matrix/block-chain')} className='font-weight-bold'>
                                                        BlockChain
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Pedestal with the help of our blockchain developers</p>
                                                </div>
                                                <a onClick={() => redirect('/competency-matrix/block-chain')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={artificialIntelligenceImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/competency-matrix/artificial-intelligence')} className='font-weight-bold'>
                                                        Artificial Intelligence
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Get a tailor-made AI</p>
                                                </div>
                                                <a onClick={() => redirect('/competency-matrix/artificial-intelligence')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={dataScienceImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/competency-matrix/data-science')} className='font-weight-bold'>
                                                        Data Science
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Implemented analytical, statistical and pragmatic approaches</p>
                                                </div>
                                                <a onClick={() => redirect('/competency-matrix/data-science')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-lg-4'>
                                <ul>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={enterpriseWebApplicationImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/competency-matrix/enterprise-web-application')} className='font-weight-bold'>
                                                        Enterprise Web Application
                                                    </a >
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Build real-time progressive web</p>
                                                </div>
                                                <a onClick={() => redirect('/competency-matrix/enterprise-web-application')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={enterpriseMobilityImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/competency-matrix/enterprise-mobility')} className='font-weight-bold'>
                                                        Enterprise Mobility
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Go-to Native App Development</p>
                                                </div>
                                                <a onClick={() => redirect('/competency-matrix/enterprise-mobility')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={qualityAssuranceImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/competency-matrix/quality-assurance')} className='font-weight-bold'>
                                                        Quality Assurance
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Eliminates dependency on external factors</p>
                                                </div>
                                                <a onClick={() => redirect('/competency-matrix/quality-assurance')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-lg-4'>
                                <ul>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={cloudComputingImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/competency-matrix/cloud-computing')} className='font-weight-bold'>
                                                        Cloud Computing
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Get-up-and-go scalable with Kubernetes</p>
                                                </div>
                                                <a onClick={() => redirect('/competency-matrix/cloud-computing')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={internetOfThingsImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/competency-matrix/internet-of-things')} className='font-weight-bold'>
                                                        Internet Of Things
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Get POC with our IOT Developer</p>
                                                </div>
                                                <a onClick={() => redirect('/competency-matrix/internet-of-things')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={devopsImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/competency-matrix/devops')} className='font-weight-bold'>
                                                        DevOps
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>High availability Zero downtime</p>
                                                </div>
                                                <a onClick={() => redirect('/competency-matrix/devops')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li className='mega-hasdropdown'>
                    {/* <Link to='#'>Professional Service(s)</Link> */}
                    <a>Competency Matrix</a>
                    <div className='mega-dropdown'>
                        <div className='row m-3'>
                            <div className='col-lg-4'>
                                <ul>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={maintenanceSupportImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/professional-services/maintenance-support')} className='font-weight-bold'>
                                                        Maintenance & Support
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Get Professional Help Desk and App Management</p>
                                                </div>
                                                <a onClick={() => redirect('/professional-services/maintenance-support')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={itConsultingImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/professional-services/it-consulting')} className='font-weight-bold'>
                                                        IT Consulting
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Very strong Implementation Partner</p>
                                                </div>
                                                <a onClick={() => redirect('/professional-services/it-consulting')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-lg-4'>
                                <ul>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={customAppDevelopmentImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/professional-services/custom-app-development')} className='font-weight-bold'>
                                                        Custom App Development
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>We Have the Web Expertise to Rely On</p>
                                                </div>
                                                <a onClick={() => redirect('/professional-services/custom-app-development')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={mobileAppDevelopmentImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/professional-services/mobile-app-development')} className='font-weight-bold'>
                                                        Mobile App Development
                                                    </a >
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Complete End-to-End Mobile Development Services</p>
                                                </div>
                                                <a onClick={() => redirect('/professional-services/mobile-app-development')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-lg-4'>
                                <ul>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={emergingTechDevelopmentImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/professional-services/emerging-tech-development')} className='font-weight-bold'>
                                                        Emerging Tech Development
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Blockchain, AI, Machine Learning, DevOps, and security</p>
                                                </div>
                                                <a onClick={() => redirect('/professional-services/emerging-tech-development')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={applicationIntegrationImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/professional-services/application-integration')} className='font-weight-bold'>
                                                        Application Integration
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Third party application integrator</p>
                                                </div>
                                                <a onClick={() => redirect('/professional-services/application-integration')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li className='mega-hasdropdown'>
                    {/* <Link to='#'>Industry Focus</Link> */}
                    <a >Industry Focus</a>
                    <div className='mega-dropdown'>
                        <div className='row m-3'>
                            <div className='col-lg-4'>
                                <ul>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={healthcarePharmaceuticalImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/industry-focus/healthcare-pharmaceutical')} className='font-weight-bold'>
                                                        Healthcare & Pharmaceutical
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Forcing enterprises to adopt digital transformation</p>
                                                </div>
                                                <a onClick={() => redirect('/industry-focus/healthcare-pharmaceutical')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={hiTechManufacturingImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/industry-focus/hi-tech-manufacturing')} className='font-weight-bold'>
                                                        Hi-Tech Manufacturing
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Disruptive forces are impacting</p>
                                                </div>
                                                <a onClick={() => redirect('/industry-focus/hi-tech-manufacturing')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={eCommerceRetailImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/industry-focus/e-commerce-retail')} className='font-weight-bold'>
                                                        E-commerce & Retail
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Agile supply chains practices</p>
                                                </div>
                                                <a onClick={() => redirect('/industry-focus/e-commerce-retail')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-lg-4'>
                                <ul>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={bankFinancialServicesImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/industry-focus/bank-financial-services')} className='font-weight-bold'>
                                                        Bank & Financial Services
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Domain competence</p>
                                                </div>
                                                <a onClick={() => redirect('/industry-focus/bank-financial-services')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={miningJewelleryImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/industry-focus/mining-jewellery')} className='font-weight-bold'>
                                                        Mining & Jewellery
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Blockchain increasing penetration</p>
                                                </div>
                                                <a onClick={() => redirect('/industry-focus/mining-jewellery')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={itTelecomImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <Link to='/industry-focus/it-telecom' className='font-weight-bold'>
                                                        IT & Telecom
                                                    </Link>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Innovation increasing acceptance</p>
                                                </div>
                                                <Link to='/industry-focus/it-telecom' className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-lg-4'>
                                <ul>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={automotiveImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/industry-focus/automotive')} className='font-weight-bold'>
                                                        Automotive
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>IOT, Machine Learning, and Cloud Native</p>
                                                </div>
                                                <a onClick={() => redirect('/industry-focus/automotive')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a >
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={educationAcademicsImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/industry-focus/education-academics')} className='font-weight-bold'>
                                                        Education & Academics
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Technology drive efficient operations</p>
                                                </div>
                                                <a onClick={() => redirect('/industry-focus/education-academics')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='featured-icon-box left-icon'>
                                            <div className='featured-icon menu-image'>
                                                <img src={othersImg} alt='post-img' />
                                            </div>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <a onClick={() => redirect('/industry-focus/others')} className='font-weight-bold'>
                                                        Others
                                                    </a>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Government, Aerospace & Defence, and etc</p>
                                                </div>
                                                <a onClick={() => redirect('/industry-focus/others')} className='ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'>
                                                    Read More <i className='ti ti-angle-double-right'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <a onClick={() => redirect('/case-studies')}  >Case Studies</a >
                </li>
            </ul>
        </nav>
    )
};