import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    MDBNavbar,
    MDBNavbarNav,
    MDBNavItem,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBHamburgerToggler,
    MDBIcon
} from 'mdbreact';

const Mobilemenu = () => {
    const [collapse, setCollapse] = useState(false);
    const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

    const toggleCollapse = () => {
        setCollapse(!collapse);
        setIsHamburgerOpen(!isHamburgerOpen); // Toggle hamburger state
    };

    const closeCollapse = () => {
        setCollapse(false);
        setIsHamburgerOpen(false); // Close hamburger icon
    };
    const navigation = useNavigate();
    const location = useLocation();
    const redirect = (path) => {
        if (!(location.pathname === path)) {

            navigation(path)
        }
        
    }
    return (
        <MDBNavbar>
              <MDBIcon
                icon={isHamburgerOpen ? 'times' : 'bars'}
                id='hamburger1'
                onClick={toggleCollapse}
                style={{ fontSize: '30px' }}
            />
            <MDBCollapse isOpen={collapse} navbar>
                <MDBNavbarNav left>
                    <MDBNavItem>
                        <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                                <span>Competency Matrix</span>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu left onClick={closeCollapse}>
                                <MDBDropdownItem  onClick={()=>redirect('/competency-matrix/block-chain')}>
                                    {/* <Link to='/competency-matrix/block-chain'>BlockChain</Link> */}
                                    <a>BlockChain</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem  onClick={()=>redirect('/competency-matrix/artificial-intelligence')}>
                                    {/* <Link to='/competency-matrix/artificial-intelligence'  >Artificial Intelligence</Link> */}
                                    <a>Artificial Intelligence</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/competency-matrix/data-science')}>
                                    {/* <Link to='/competency-matrix/data-science'>Data Science</Link> */}
                                    <a >Data Science</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/competency-matrix/enterprise-web-application')}>
                                    {/* <Link to='/competency-matrix/enterprise-web-application'>Enterprise Web Application</Link> */}
                                    <a >Enterprise Web Application</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/competency-matrix/enterprise-mobility')}>
                                    {/* <Link to='/competency-matrix/enterprise-mobility'>Enterprise Mobility</Link> */}
                                    <a >Enterprise Mobility</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/competency-matrix/quality-assurance')}>
                                    {/* <Link to='/competency-matrix/quality-assurance'>Quality Assurance</Link> */}
                                    <a >Quality Assurance</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/competency-matrix/cloud-computing')}>
                                    {/* <Link to='/competency-matrix/cloud-computing'>Cloud Computing</Link> */}
                                    <a >Cloud Computing</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/competency-matrix/internet-of-things')}>
                                    {/* <Link to='/competency-matrix/internet-of-things'>Internet Of Things</Link> */}
                                    <a >Internet Of Things</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/competency-matrix/devops')}>
                                    {/* <Link to='/competency-matrix/devops'>DevOps</Link> */}
                                    <a >DevOps</a>
                                </MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                    </MDBNavItem>
                    <MDBNavItem>
                        <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                                <span>Professional Service(s)</span>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu  left onClick={closeCollapse}>
                                <MDBDropdownItem onClick={()=>redirect('/professional-services/maintenance-support')}>
                                    {/* <Link to='/professional-services/maintenance-support'>Maintenance & Support</Link> */}
                                    <a >Maintenance & Support</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/professional-services/it-consulting')}>
                                    {/* <Link to='/professional-services/it-consulting'>IT Consulting</Link> */}
                                    <a >IT Consulting</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/professional-services/custom-app-development')}>
                                    {/* <Link to='/professional-services/custom-app-development'>Custom App Development</Link> */}
                                    <a >Custom App Development</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/professional-services/mobile-app-development')}>
                                    {/* <Link to='/professional-services/mobile-app-development'>Mobile App Development</Link> */}
                                    <a >Mobile App Development</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/professional-services/emerging-tech-development')}>
                                    {/* <Link to='/professional-services/emerging-tech-development'>Emerging Tech Development</Link> */}
                                    <a >Emerging Tech Development</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/professional-services/application-integration')}>
                                    {/* <Link to='/professional-services/application-integration'>Application Integration</Link> */}
                                    <a >Application Integration</a>
                                </MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                    </MDBNavItem>
                    <MDBNavItem>
                        <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                                <span>Industry Focus</span>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu  left onClick={closeCollapse}>
                                <MDBDropdownItem onClick={()=>redirect('/industry-focus/healthcare-pharmaceutical')}>
                                    {/* <Link to='/industry-focus/healthcare-pharmaceutical'>Healthcare & Pharmaceutical</Link> */}
                                    <a >Healthcare & Pharmaceutical</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/industry-focus/hi-tech-manufacturing')}>
                                    {/* <Link to='/industry-focus/hi-tech-manufacturing'>Hi-Tech Manufacturing</Link> */}
                                    <a >Hi-Tech Manufacturing</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/industry-focus/e-commerce-retail')}>
                                    {/* <Link to='/industry-focus/e-commerce-retail'>E-commerce & Retail</Link> */}
                                    <a >E-commerce & Retail</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/industry-focus/bank-financial-services')}>
                                    {/* <Link to='/industry-focus/bank-financial-services'>Bank & Financial Services</Link> */}
                                    <a >Bank & Financial Services</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/industry-focus/mining-jewellery')}>
                                    {/* <Link to='/industry-focus/mining-jewellery'>Mining & Jewellery</Link> */}
                                    <a >Mining & Jewellery</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                    {/* <Link to='/industry-focus/it-telecom'>IT & Telecom</Link> */}
                                    <a onClick={()=>redirect('/industry-focus/it-telecom')}>IT & Telecom</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/industry-focus/automotive')}>
                                    {/* <Link to='/industry-focus/automotive'>Automotive</Link> */}
                                    <a >Automotive</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/industry-focus/education-academics')}>
                                    {/* <Link to='/industry-focus/education-academics'>Education & Academics</Link> */}
                                    <a >Education & Academics</a>
                                </MDBDropdownItem>
                                <MDBDropdownItem onClick={()=>redirect('/industry-focus/others')}>
                                    {/* <Link to='/industry-focus/others'>Others</Link> */}
                                    <a >Others</a>
                                </MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                    </MDBNavItem>
                    <MDBNavItem left onClick={closeCollapse}>
                        <MDBDropdownItem className='nav-link'onClick={()=>redirect('/case-studies')} >
                            {/* <Link to='/case-studies'>Case Studies</Link> */}
                            <a >Case Studies</a>
                        </MDBDropdownItem>
                    </MDBNavItem>
                    <MDBNavItem onClick={closeCollapse}>
                        <MDBDropdownItem className='nav-link' left  onClick={()=>redirect('/faq')}>
                            {/* <Link to='/faq'>FAQs</Link> */}
                            <a>FAQs</a>
                        </MDBDropdownItem>
                    </MDBNavItem>
                    <MDBNavItem onClick={closeCollapse}>
                        <MDBDropdownItem className='nav-link' left onClick={()=>redirect('/aboutus')} >
                            {/* <Link to='/aboutus'>About Us</Link> */}
                            <a >About Us</a>
                        </MDBDropdownItem>
                    </MDBNavItem>
                    <MDBNavItem onClick={closeCollapse}>
                        <MDBDropdownItem className='nav-link' left onClick={()=>redirect('/industry-trends')}>
                            {/* <Link to='/industry-trends'>Industry Trends</Link> */}
                            <a >Industry Trends</a>
                        </MDBDropdownItem>
                    </MDBNavItem>
                    <MDBNavItem onClick={closeCollapse}>
                        <MDBDropdownItem className='nav-link' left onClick={()=>redirect('/careers')}>
                            {/* <Link to='/careers'>Careers</Link> */}
                            <a >Careers</a>
                        </MDBDropdownItem>
                    </MDBNavItem>
                    <MDBNavItem onClick={closeCollapse}>
                        <MDBDropdownItem className='nav-link' left onClick={()=>redirect('/contact')}>
                            {/* <Link to='/contact'>Contact Us</Link> */}
                            <a >Contact Us</a>
                        </MDBDropdownItem>
                    </MDBNavItem>
                    <MDBNavItem onClick={closeCollapse}>
                        <MDBDropdownItem className='nav-link' left onClick={()=>redirect('/blog')}>
                            {/* <Link to='/blog'>Blog</Link> */}
                            <a >Blog</a>
                        </MDBDropdownItem>
                    </MDBNavItem>
                </MDBNavbarNav>
            </MDBCollapse>
        </MDBNavbar>
    );
};

export default Mobilemenu;