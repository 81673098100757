import ClutchImg from '../../assets/images/blog/clutch/list.webp';
import CovidImg from '../../assets/images/blog/covid/list.webp';
import UIUXDesignImg from '../../assets/images/blog/ui-ux-design/list.webp';
import FrontEndDevelopmentImg from '../../assets/images/blog/front-end-development/list.webp';
import FullStackDevelopmentImg from '../../assets/images/blog/full-stack-development/list.webp';
import BackEndDevelopmentImg from '../../assets/images/blog/back-end-development/list.webp';
import CertifiedCloudExpertImg from '../../assets/images/blog/certified-cloud-expert/list.webp';
import CertifiedKubernetesExpertImg from '../../assets/images/blog/certified-kubernetes-expert/list.webp';
import CertifiedDevopsExpertImg from '../../assets/images/blog/certified-devops-expert/list.webp';
import IosDevelopmentImg from '../../assets/images/blog/ios-development/list.webp';
import AndroidDevelopmentImg from '../../assets/images/blog/android-development/list.webp';
import HybridDevelopmentImg from '../../assets/images/blog/hybrid-development/list.webp';
import WebTestingImg from '../../assets/images/blog/web-testing/list.webp';
import ApiTestingImg from '../../assets/images/blog/api-testing/list.webp';
import PerformanceTestingImg from '../../assets/images/blog/performance-testing/list.webp';
import TestAutomationImg from '../../assets/images/blog/test-automation/list.webp';
import MobileTestingImg from '../../assets/images/blog/mobile-testing/list.webp';

const BlogsItems = [
    {
        id: 1,
        name: 'Clutch Names Spidron Tech as a Top Developer in India for 2022',
        desc: 'The most important businesses are the ones that make positive changes in the lives and operations of their clients. This has been our goal...',
        link: '/blog/clutch-names-spidron-tech-as-a-top-developer-in-india-for-2022',
        imag: ClutchImg
    }, {
        id: 2,
        name: 'Our initiative towards the growth of global economy in Covid-19 Crisis',
        desc: 'It’s been so long that the world is suffering from a coronavirus pandemic. The situation is gloomy and heartbreaking, you ...',
        link: '/blog/covid',
        imag: CovidImg
    }, {
        id: 3,
        name: 'UI / UX Design And Development',
        desc: 'We are extremely perceptive of your business challenges and are prepared to help you reach your business goals by implementing intuitive ...',
        link: '/blog/ui-ux-design',
        imag: UIUXDesignImg
    }, {
        id: 4,
        name: 'Front End Development',
        desc: 'Our team of experienced Front-End developers have overseen the delivery of upwards of “X” of Projects over the course of six glorious years.',
        link: '/blog/front-end-development',
        imag: FrontEndDevelopmentImg
    }, {
        id: 5,
        name: 'Full Stack Development',
        desc: 'We offer the best app development services in the market by first understanding a business’s goals and then developing a bespoke solution for them.',
        link: '/blog/full-stack-development',
        imag: FullStackDevelopmentImg
    }, {
        id: 6,
        name: 'Back End Development',
        desc: 'We have delivered upwards of “x” goods to our clients while providing quality and insight. Our products cover a wide range of industries, ...',
        link: '/blog/back-end-development',
        imag: BackEndDevelopmentImg
    }, {
        id: 7,
        name: 'Certified Cloud Expert',
        desc: 'Spidrontech maintains a team of well-established cloud experts with an immaculate track record in successfully and efficiently developing and ...',
        link: '/blog/certified-cloud-expert',
        imag: CertifiedCloudExpertImg
    }, {
        id: 8,
        name: 'Certified Kubernetes Expert',
        desc: 'Kubernetes infrastructure for all cloud, public and on-premise client business requirements is available through our industry-leading expertise ...',
        link: '/blog/certified-kubernetes-expert',
        imag: CertifiedKubernetesExpertImg
    }, {
        id: 9,
        name: 'Certified DevOps Expert',
        desc: 'DevOps professes to create a new culture that bridges the gap between the two fundamental operational teams, Development (Dev) ...',
        link: '/blog/certified-devops-expert',
        imag: CertifiedDevopsExpertImg
    }, {
        id: 10,
        name: 'IOS Development',
        desc: 'As a leading iOS app development firm, Spidrontech is committed to fulfilling the high expectations of next-generation iPhone and iPad users, as well as existing ones.',
        link: '/blog/ios-development',
        imag: IosDevelopmentImg
    }, {
        id: 11,
        name: 'Android Development',
        desc: 'As a leading Android development firm, not only do we design outstanding mobile apps and digital experiences, but we also stay up to date on the latest ...',
        link: '/blog/android-development',
        imag: AndroidDevelopmentImg
    }, {
        id: 12,
        name: 'Hybrid Development',
        desc: 'SpidronTech proudly maintains a team of professional developers that are well-versed in combining the finest of native and web applications and ...',
        link: '/blog/hybrid-development',
        imag: HybridDevelopmentImg
    }, {
        id: 13,
        name: 'Web Testing',
        desc: 'Before your website goes live, it is of quintessential importance that you perform various sorts of testing processes in order to ensure that an ...',
        link: '/blog/web-testing',
        imag: WebTestingImg
    }, {
        id: 14,
        name: 'API Testing',
        desc: 'API testing ensures that an application’s output is well-structured and may be reused by other applications. As a result, it is a set of ...',
        link: '/blog/api-testing',
        imag: ApiTestingImg
    }, {
        id: 15,
        name: 'Performance Testing',
        desc: 'Regular maintenance and upkeep of brand-deployed apps is critical for continuing expansion on the Y axis, both in terms of site traffic and fiscal advancement.',
        link: '/blog/performance-testing',
        imag: PerformanceTestingImg
    }, {
        id: 16,
        name: 'Test automation',
        desc: 'Tests can be conducted manually or automatically, depending on your preferences. Manual testing, on the other hand, demands that you be present and ...',
        link: '/blog/test-automation',
        imag: TestAutomationImg
    }, {
        id: 17,
        name: 'Mobile Testing',
        desc: 'Apps for mobile devices are essential for business. A frequent testing programme is needed to make sure everything is running well. SpidronTech is aware ...',
        link: '/blog/mobile-testing',
        imag: MobileTestingImg
    }
];

export default BlogsItems;