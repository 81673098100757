import Router from './routes';
import { BrowserRouter } from 'react-router-dom';

import './assets/scss/app.scss';

export default function App() {
    return (
        <BrowserRouter>
            <Router />
        </BrowserRouter>
    );
}