import React from 'react'
import { Outlet } from 'react-router-dom'

const CaseStudies = () => {
    const style=`.featured-imagebox-blog{
        min-height:498px !important;
    }`
  return (
     <div>
         <style>{style}</style>
        <Outlet/>
     </div>
  )
}

export default CaseStudies