import React, { useState } from 'react'
import { useLocation, useWindowScroll } from 'react-use';
import {
    MDBModal,
    MDBModalBody,
    MDBModalHeader
} from 'mdbreact'
import { Menu } from './menu';
import Mobilemenu from './Mobile_menu';
import { Link, useNavigate } from 'react-router-dom';
import logoImg from '../../assets/images/logo.webp';
import ContactForm from '../Section/ContactForm';

const Header = () => {
    const [show, setShow] = useState(false);
    const [getAQuote, setGetAQuote] = useState(false);
    const navigation = useNavigate();
    const location = useLocation();
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const routes = [
        '/',
        '/contact',
        '/aboutus',
        '/careers',
        '/cancellation-and-refund-policy',
        '/terms-conditions',
        '/privacy-policy',
        '/our-working-process',
        '/faq',
        '/cookie',
        '/sales-partner-program',
        '/testimonial',
        '/competency-matrix/block-chain',
        '/competency-matrix/artificial-intelligence',
        '/competency-matrix/data-science',
        '/competency-matrix/enterprise-web-application',
        '/competency-matrix/enterprise-mobility',
        '/competency-matrix/quality-assurance',
        '/competency-matrix/cloud-computing',
        '/competency-matrix/internet-of-things',
        '/competency-matrix/devops',
        '/blog',
        '/industry-trends',
        '/services',
    ]
    const redirect = (path) => {
        if (!(location.pathname === path)) {
            navigation(path)
        }
    }
    const closeGetAQuote = () => {
        setGetAQuote(!getAQuote)
    };
    const { y: pageYOffset } = useWindowScroll();
    const handleChange = (e) => {
        const { value } = e.target
        setSearch(value)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const matchingRoute = routes.find(route => {
            const formattedRoute = route.replace(/-/g, ' ');
            return new RegExp(search.replace(/ /g, '\\s*'), 'i').test(formattedRoute);
        });
        if (matchingRoute) {
            navigate(matchingRoute);
            setShow(!show);
        }
    }
    return (
        <header id='masthead' className='header ttm-header-style-01'>
            <div className={'ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix ' + (pageYOffset >= 10 ? 'fixed-wrapper' : '')}>
                <div className='container-xl'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='d-flex'>
                                <ul className='top-contact text-left'>
                                    <li><i className='fas fa-map-marker-alt' />&nbsp;&nbsp;Leicester LE4 7SB, UK</li>
                                    <li><i className='far fa-envelope' />&nbsp;&nbsp;<a href='mailto:contact@spidrontech.com.com'>contact@spidrontech.com</a></li>
                                </ul>
                                <div className='ttm-social-links-wrapper list-inline ml-5'>
                                    <ul className='social-icons'>
                                        <li>
                                            <a href='https://www.facebook.com/SpidronTech-103103608465465' target='_blank' rel='noreferrer' className='tooltip-bottom' data-tooltip='Facebook'><i className='fab fa-facebook-f' /></a>
                                        </li>
                                        <li>
                                            <a href='https://twitter.com/spidrontech' target='_blank' rel='noreferrer' className='tooltip-bottom' data-tooltip='Twitter'><i className='fab fa-twitter' /></a>
                                        </li>
                                        <li>
                                            <a href='https://www.linkedin.com/company/spidron-tech-uk-ltd/about/?viewAsMember=true' target='_blank' rel='noreferrer' className='tooltip-bottom' data-tooltip=' aedin'><i className='fab fa-linkedin-in' /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='topbar-right text-right d-flex justify-content-end'>
                                <ul id='menu-footer-menu' className='footer-nav-menu copyright'>
                                    <li><a onClick={() => redirect('/aboutus')}  >About Us</a></li>
                                    <li><a onClick={() => redirect('/industry-trends')}>Industry Trends</a></li>
                                    <li><a onClick={() => redirect('/testimonial')}>Testimonial</a></li>
                                    <li>< a onClick={() => redirect('/contact')}>Contact Us</a></li>
                                </ul>
                                <div className='header-btn'>
                                    <Link to='#'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            closeGetAQuote();
                                        }}
                                        className='ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor'
                                    >Get A Quote</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ttm-header-wrap'>
                <div id='navbar' className={'ttm-stickable-header clearfix ' + (pageYOffset >= 10 ? 'fixed-header' : '')}>
                    <div className='site-header-menu'>
                        <div className='container-xl'>
                            <div className='site-branding'>
                                <a className='home-link' onClick={()=>redirect('/')}   title='SpidronTech' rel='home'>
                                    <img id='logo-img' className='img-fluid' alt='logo' src={logoImg} />
                                </a>
                            </div>
                            <div className='site-navigation'>
                                <div className='ttm-right-header'>
                                    <ul className='ttm-header-icons'>
                                        <li className='ttm-header-search-link ttm-bgcolor-skincolor ttm-textcolor-white'>
                                            <a onClick={() => setShow(!show)}> {show ? <i className='ti-close' /> : <i className='ti-search' />}</a>
                                            {show && <div className='ttm-search-overlay'>
                                                <form onSubmit={handleSubmit} className='ttm-site-searchform'>
                                                    <div className='w-search-form-h'>
                                                        <div className='w-search-form-row'>
                                                            <div className='w-search-input'>
                                                                <input type='search' className='field searchform-s' name='search' placeholder='Type Word Then Enter...' onChange={handleChange} />
                                                                <button type='submit'>
                                                                    <i className='ti ti-search' />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>}
                                        </li>
                                    </ul>
                                </div>
                                <Menu />
                                <div className='mobilemenu'><Mobilemenu /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MDBModal isOpen={getAQuote} toggle={closeGetAQuote}>
                <MDBModalHeader toggle={closeGetAQuote}>Get A Quote</MDBModalHeader>
                <MDBModalBody>
                    <ContactForm type='Get A Quote' />
                </MDBModalBody>
            </MDBModal>
        </header>
    )
}

export default Header;